html, body{
  font-size: 0.8333333vw;

  @media screen and (max-width: 1440px){
    font-size: 75%;
  }
}

h1, h2{
  font-size: rem-calc(72px);
  font-family: $primary-font;

  @media screen and (max-width: 1919px){
    font-size:rem-calc(68px);
  }

  @media screen and (max-width: 1599px){
    font-size:rem-calc(64px);
  }

  @media screen and (max-width: $large){
    font-size:rem-calc(54px);
  }
}

h3{
  font-size: rem-calc(32px);
  line-height:1.6;
  font-family: $primary-font;

  @media screen and (max-height: 500px){
    font-size: 1.6rem;
  }

  
}


h4{
  font-size: rem-calc(28px);
  line-height:1.6;
  font-family: $primary-font;

  @media screen and (max-width: $large){
    font-size: rem-calc(22px);
  }
  
}

p{
  font-size: 1rem;
  font-family: $secondary-font;
}

a{
  outline:none;
}

img{
  max-width:100%;
  max-height:100%;
}

.swiper-button-next, .swiper-button-prev{
  background-color: rgb(0, 0, 0);

  background-size: 66px 66px;
  width:115px;
  height:90px;
  opacity: 0.5;
  display:flex;

  @media screen and (max-width: $medium){
    width:85px;
    height: 60px;
    background-size: 45px 45px;
  }

  transition: opacity 0.3s, transform 0.2s;

  &:hover{
    transform: scale(1.1)
  }

  &:active{
    transform: scale(1.2);
  }
  &.swiper-button-disabled{
    opacity: 0.0;
  }
}

.swiper-button-next{
  background-position-x: 30%;
}

.swiper-button-prev{
  background-position-x:  70%;
}

@keyframes rotate {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}