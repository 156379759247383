.slider-section{
  display:flex;
  padding-top: 170px;

  @media screen and (max-height: 960px){
    padding-top:100px;
  }

  @media screen and (max-height: 750px){
      padding-top: $medium-column-padding-top;
      padding-bottom: $medium-column-padding-top;
  }

  @media screen and (max-width: 850px){
    flex-wrap: wrap;
    padding-top: $medium-column-padding-top;
  }

  &__left-column{
    width: $left-column-width;
    padding-left: $column-padding-left;
    padding-right: 30px;

    @media screen and (max-width: $large){
      padding-left: $large-column-padding-left;
      padding-right: $large-column-padding-left;
    }

    @media screen and (max-width: $medium){
      padding-left: $medium-column-padding-left;
      padding-right: $medium-column-padding-left;
    }

    @media screen and (max-width: 850px){
      width:100%;
    }

    p {
      font-size: 1.3rem;
    }
  }

  &__right-column{
    width:$center-column-width + $right-column-width;
    padding-right: 35px;

    @media screen and (max-width: 850px){
      width:100%;
      padding-right: $medium-column-padding-left;
      padding-left: $medium-column-padding-left;
      
    }
  }

  &__title{
    margin-bottom:70px;
    @media screen and (max-width: $large){
      margin-bottom: 30px;
    }
  }

  &__list{
    @media screen and (max-width: 850px){
      display:flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      position:absolute;
      width:300px;
      z-index: 9;
      background-color:white;

      display: none;

      box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.5);
    }
  }

  &__list-item{
    @media screen and (max-width: 850px){
      width:100%;
    }
  }

  &__item{
    font-size: rem-calc(34px);
    padding-top: 30px;
    display: inline-block;

    transition:color 0.3s, opacity 0.3s;

    @media screen and (max-width: $large), screen and (max-height: 550px){
      font-size: rem-calc(26px);
      padding-top:15px;
    }

    @media screen and (max-width: $medium){
      font-size: rem-calc(24px);
      padding-top:10px;
    }

    @media screen and (max-width: 850px){
      padding-bottom:10px;
      padding-left: 30px;
      padding-right: 10px;
      font-size: 14px;
      display:inline-block;
      border-radius: 10px;
      margin-left: 10px;
      &:first-child{
        margin-left:0px;
      }
    }

   
    &.loading{
      position: relative;
      &:after{
        content: "";
        display:inline-block;
        position: absolute;
        left: -50px;
        bottom:10%;
        width: rem-calc(14px);
        height:rem-calc(14px);

        border-radius: 50%;
        border: 3px solid white;
        border-top-color: black;
        border-bottom-color: black;

        animation: rotate 0.7s linear infinite;

        margin-left: 20px;

        @media screen and (max-width: $medium){
          width: 5px;
          height:5px;          
        }

        @media screen and (max-width: 850px){
          left: -10px;
          top: 10px;
          bottom:auto;
        }

      }
    }

    &:hover{
      opacity: 0.6;
    }

    &.active{
      color:$primary-color;
    }
  }

  &__slider{
    .swiper-wrapper{
    }

    @media screen and (max-width: 850px){
      .swiper-scrollbar{
        bottom: 15px;
      }
    }
  }

  &__scrollbar{
    background-color:$primary-color;
    height:2px!important;
    position:relative;
    .swiper-scrollbar-drag{
      background-color:$primary-color;
      position:absolute;
      height: 6px;
      top:-2px;
    }
  }
  &__slide{
    padding-bottom: 20px; 
  }
  &__nav{
    @media screen and (max-width: 850px){
      margin-bottom: 10px;
    }
  }

  &__dropdown-button{
    display:none;

    @media screen and (max-width: 850px){
      display:inline-block;
      width:auto;

      font-size: 14px;

      color:white!important;
      font-family: $secondary-font;
      padding: 10px 30px;
      background-color:$primary-color;
      

      &:hover{
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

.slider-section-sneak{

  &:hover{
    .slider-section-sneak__image{
     transform:scale(1.1);
    }
  }

  &__image{
    height:61vh;
    width:100%;
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    backface-visibility: hidden;

    transition: transform 0.3s;
  
  }

  &__image-container{
    overflow:hidden;
  }

  &__title{
    padding-top:35px;
    padding-left:35px;
    margin-bottom: 10px;

  }
  &__category{
    color:$primary-color;
    padding-left:35px; 
    display:inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  
  }


}

