.see-also{
  display: flex;
  height:100%;
  background-color:$grey;
  color:white;
  padding-top:rem-calc(170px);

  @media screen and (max-width: $medium){
    flex-wrap:wrap;
    padding-top:0;
    height:auto;


  }

  &__left-column{
    width: $left-column-width;  
    height: 800px;
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    position:relative;

    @media screen and (max-width: $medium){
      width:100%;
      height:auto;
      padding-top:$medium-column-padding-top;
      padding-bottom:$medium-column-padding-top;
      padding-left:$medium-column-padding-left;
      padding-right:$medium-column-padding-left;
      align-items:center;
    }
    
    h2{
      width:auto;

      @media screen and (max-width: $medium){
        padding-bottom: $medium-column-padding-top;
      }
    }

    
  }

  &__right-column{
    width: $right-column-width + $center-column-width;
    padding-right:35px;

    @media screen and (max-width: $medium){
      width:100%;
      padding-left:$medium-column-padding-left;
      padding-right:$medium-column-padding-left;
      
    }
  }

  &__button{
    width:auto;
    font-size: rem-calc(36px);

    display:flex;
    align-items:center;
    position:absolute;
    top:40%;

    &:hover{
      i{
        transform:scale(1.1);
      }
    }
    @media screen and (max-width: 530px){
      font-size: rem-calc(20px);
    }

    span{
      display:inline-block;
      width:calc(100% - 62px);
      @media screen and (max-width: $medium){
        width:auto;
      }

     
    }

    i{
      display: inline-block;
      height: 65px;
      width: 77px;

      background-size: contain;
      background-repeat:no-repeat;
      
      margin-right:30px;

      transition: transform 0.3s;

      @media screen and (max-width: 530px){
        height: 45px;
        width: 57px;
        margin-right:20px;
        
      }
      
    }
    
    @media screen and (max-width: $medium){
      position:static;
      justify-content: center;
      align-items:center;
      width:auto;
    }
  }

  &__title{
    width:100%;
    text-align: center;
  }

  &__content{
    width:75%;

    @media screen and (max-width: $medium){
      width:100%;
    }
  }
}