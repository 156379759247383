.article{
  display: flex;
  height:100%;

  @media screen and (max-width: $small){
    flex-wrap:wrap;
    height:auto;
  }
  &__right-column{
    width: $center-column-width + $right-column-width;
    height: 100%;

    padding-top: $column-padding-top;
    padding-bottom: $column-padding-top;
    padding-left: $column-padding-left;
    padding-right: $column-padding-left;

    @media screen and (max-width: $large){
      padding-top: $large-column-padding-top;
      padding-bottom: $large-column-padding-top;
      padding-left: $large-column-padding-left;
      padding-right: $large-column-padding-left;
    }

    @media screen and (max-width: $medium){
      padding-top: $medium-column-padding-top;
      padding-bottom: $medium-column-padding-top;
      padding-left: $medium-column-padding-left;
      padding-right: $medium-column-padding-left;
    }

    @media screen and (max-width: $small){
      width:100%;
      order: 1;
    }
  }

  &__left-column{
    width: $left-column-width;
    height: 100%;
    
    padding-top: $column-padding-top;
    padding-bottom: $column-padding-top;
    padding-left: $column-padding-left;
    padding-right: $column-padding-left;
    display:flex;
    align-items:center;
    justify-content: flex-start;

    border-right: 1px solid $primary-color;

    @media screen and (max-width: $large){
      padding-top: $large-column-padding-top;
      padding-bottom: $large-column-padding-top;
      padding-left: $large-column-padding-left;
      padding-right: $large-column-padding-left;
    }

    @media screen and (max-width: $medium){
      padding-top: $medium-column-padding-top;
      padding-bottom: $medium-column-padding-top;
      padding-left: $medium-column-padding-left;
      padding-right: $medium-column-padding-left;
    }

    @media screen and (max-width: $small){
      width:100%;
      order:2;
      border:none;
    }

    img{
      margin-bottom: 50px;
      transition: transform 0.3s;
      @media screen and (max-width: $small){
       margin-bottom: 10px;
      }
    }

    a{
      &:hover{
        img{
          transform: scale(1.1);
        }
      }
    }
  }

  &__content{
   @extend .about__content;
  }
}