.about{
  display:flex;
  height:100%;
  border-bottom:1px solid $primary-color;

  @media screen and (max-width: $small){
    flex-wrap:wrap;
    overflow-y: auto;
    padding-top: $small-column-padding-top;
    border-bottom: $small-column-padding-top solid white;
  }

  &__left-column{
    width: $left-column-width;
    border-right: 1px solid $primary-color;
    padding-top: $column-padding-top;
    padding-left: $column-padding-left;
    white-space: nowrap;

    @media screen and (max-width: $large){
      padding-left: $large-column-padding-left;
    }

    @media screen and (max-width: $medium){
      width: 40%;
      padding-left: $medium-column-padding-left;
    }

    @media screen and (max-width: $small){
      width:100%;
      border-right:none;

      padding-top: $small-column-padding-top;
      padding-bottom: $small-column-padding-top;

    }

    h2 {
      font-size: 3.6rem;
    }

    h3 {
      font-size: 1.8rem;
    }
  }
  
  &__center-column{
    width: $center-column-width;
    padding-left: $column-padding-left;
    padding-right: $column-padding-left;
    padding-top: $column-padding-top;
    padding-bottom: $column-padding-top;
    
    @media screen and (max-width: $large){
      padding-left: $large-column-padding-left;
      padding-right: $large-column-padding-left;
    }
    
    @media screen and (max-width: $medium){
      width: 60%;
      padding-left: $medium-column-padding-left;
      padding-right: $medium-column-padding-left;
    }

    @media screen and (max-width: $small){
      width:100%;
      padding-bottom: 0;
    }
  }

  &__right-column{
    width: $right-column-width;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: $medium){
      width:0;
    }
  }

  &__list{
    margin-top:110px;
    
    @media screen and (max-width: $small), screen and (max-height: 600px){
      margin-top: 40px;
    }
  }

  &__list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:25px 0;
    border-bottom:1px solid $primary-color;

    @media screen and (max-width: $small){
      padding:15px 0;
    }
  }

  &__count{
    font-size:rem-calc(60px);
    display:inline-block;
    width:33%;

    @media screen and (max-width: $large){
      width: 23%;
      font-size: rem-calc(50px);
    }
  }

  &__value{
    font-size: rem-calc(28px);
    // font-weight: bold;
    display:inline-block;
    width:66%;

    @media screen and (max-width: $large){
      width: 77%;
      font-size: rem-calc(24px);
      
    }
  }

  &__content{
    overflow-y: auto;
    height:100%;
    h4{
      margin-bottom: 100px;

      @media screen and (max-width: $large){
        margin-bottom: 40px;
      }

      @media screen and (max-width: $small){
        margin-bottom:20px;
      }
    }
    p{
      margin-bottom: 40px;
      line-height: 1.5;
      padding-right:20px;

      @media screen and (max-width: $large){
        margin-bottom:15px;
      }
    }
  }
}