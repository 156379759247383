.offer{
  height:100%;
  display:flex;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $small){
    flex-wrap: wrap;
  }

  &__left-column{
    width: $left-column-width + $center-column-width;
    color:white;
    padding-top: $column-padding-top;
    padding-left: $column-padding-left;

    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;

    position:relative; 

    //overlay
    &:before{
      content: "";
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color: rgba(0,0,0,0.75);
    }

    @media screen and (max-width: $large){
      padding-left:$large-column-padding-left;
      padding-right:$large-column-padding-left;
      padding-top:$large-column-padding-top;
    }

    @media screen and (max-width: $medium), screen and (max-height: 650px){
      padding-left:$medium-column-padding-left;
      padding-right:$medium-column-padding-left;
      padding-top:$medium-column-padding-top;
      padding-bottom:$medium-column-padding-top;
    }

    @media screen and (max-width: $small){
      width:100%;
      order: 2;
    }

  }

  &__right-column{
    width: $right-column-width;
    
    @media screen and (max-width: 800px){
      width:133px;
    }

    @media screen and (max-width: $small){
      width:100%;
      order:1;
    }
  }

  &__title{
    margin-bottom:100px;
    position: relative;

    @media screen and (max-width: 1919px){
      margin-bottom: 40px;
    }

    @media screen and (max-width: $large){
      margin-bottom: $large-column-padding-top;
    }

    @media screen and (max-width: $medium), screen and (max-height: 650px){
      padding-bottom:$medium-column-padding-top;
    }

    @media screen and (max-height: 600px){
      margin-bottom: 15px;
    }
  }

  &__content{
    display:flex;
    justify-content: space-between;
    padding-right:100px;
    padding-bottom: 40px;
    position: relative;

    @media screen and (max-width: $large){
      padding-right: 40px;
      padding-bottom: 20px;
    }

    @media screen and (max-height: 640px){
      padding-bottom: 10px;
    }

    @media screen and (max-width: $small){
      padding-right: 0;
      padding-left: 0;
    }

    @media screen and (max-width: 600px){
      flex-wrap:wrap;
    }

    p{
      line-height:1.7;
      font-weight: lighter;

      @media screen and (max-width: 600px){
        margin-bottom:10px;
      }
    }
  }

  &__column{
    width: 46%;

    @media screen and (max-width: 600px){
      width:100%;
    }
  }
}

.vertical-bars{
  width:85%;
  position: relative;
  
  @media screen and (max-width: $large){
    width:100%;
  }

  &__row{
    display:flex;

    @media screen and (max-width: $medium), screen and (max-height: 650px){
      align-items:flex-start;
      height : 354px;
    }

    @media screen and (max-width: 500px){
      height:auto;
      flex-wrap:wrap;
      align-items:center;
      justify-content: flex-start;
    }
  }
  
  &__title{
    margin-bottom: 30px;
  }

  &__column{
    width: 260px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1100px){
      width: 240px;;
    }

    @media screen and (max-width: 500px){
      width:100%;
    }
  }
}

.bar{
  display:flex;
  flex-direction: column;

  position:relative;

  @media screen and (max-width: 500px){
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  &__icon{
    width: 90px;
    height:70px;
    margin-bottom: 35px;
    color:white;

    display:flex;
    align-items:center;
    justify-content: center;
    @media screen and (max-width: 500px){
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__text{
    position: relative;
    background-color: #b6916e;
    width: 214px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    transform-origin: top left;
    font-size: 17px;
    
    @media screen and (max-height: 725px){
      width: 180px;
    }

    @media screen and (max-width: 500px){
      transform:none;
      position:static;
    }
  }
}