//LOCAL VARS
$top-bar-padding : 33px;
$top-bar-width : 318px;


$button-top : 30px;
$button-left : $top-bar-padding;
$button-size: 30px;


.top-bar{
  // height:0;
  // width: 100%;
  // overflow-x: hidden;
  // margin:0;
  position: relative;
  z-index: 5;

  &.active{
    .top-bar__menu-wrapper{
      transform:translateX(0);
      box-shadow: -2px 0px 5px 0px rgba(0,0,0,0.2);
    }

    .top-bar__button{
      left:0;
      right:auto;
      transform: translateX(0);
    }
  }

  &__menu-wrapper{
    position:fixed;
    right:0;
    left:auto;
    padding-top:$button-top * 2 + $button-size;
    padding-left: $top-bar-padding;
    padding-right:$top-bar-padding;

    width:$right-column-width;
    height:100vh;

    background-color:white;

    transform:translateX(100%);
    transition: transform 0.3s;


    @media screen and (max-width: 1649px){
      width: 275px;
    }

    @media screen and (max-width: 965px){
      width:100%;
      display:flex;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 100px;
    }

    @media screen and (max-height: 750px){
      display:flex;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 100px;
    }
    @media screen and (max-height: 750px){
      padding-bottom: 70px;
     
    }
    
    @media screen and (max-height: 550px){
      padding-top: 60px;
      
    }
    
    @media screen and (max-width: 966px) and  (max-height: 500px){

      flex-direction: row;
    }
  }

  &__button{
    position:absolute;
    z-index: 5;
    width:auto;
    top: $button-top;
    left: -20px;
    font-size: $button-size;
    color:$primary-color;

    transform: translateX(-100%);
    transition: right 0.4s, transform 0.2s;

    @media screen and (max-height: 550px){
      top: 0px;
      left: 10px;
    }

    @media screen and (max-width: $medium){
      top: 0px;
      left: 10px;
    }
    
  }
}