.section{
  height:100vh;
  @media screen and (max-width: $medium), screen and (max-height: 650px){
    height:auto;
    min-height:0;
  }
}

.banner.section{
  @media screen and (max-width: $medium), screen and (max-height: 650px){
    height:auto;
    min-height: 100vh;
  }
}