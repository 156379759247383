.map{
  width:100%;
  height:100%;

  transition: width 0.3s;
  
  &__overlay{
    position:absolute;
    z-index: 4;
    top:0;
    left:0;

    width:100%;
    height:100%;

    background-color: rgba(0,0,0,0.8);

    transition: background-color 0.3s;

    cursor:pointer;

    @media screen and (max-width: $medium){
      display:none;
    }

    &:hover{
      background-color: rgba(0,0,0,0.6);
    }

    &:before{
      content: "";
      position:absolute;
      top:50%;
      left:50%;
      
      width:100px;
      height:100px;
      z-index: 5;

      background-image: url('../img/k-sign.svg'); 
      transform:translate(-50%, -50%);
    }
  }

  &__exit-button{
    position:absolute;
    z-index: 9;
    top:20px;
    left:calc(100% - 20px);

    cursor:pointer;

    transform: translateX(-100%);

    width:50px;
    height:50px;
    background-image: url('../img/exit.svg');
    background-size:cover;

    opacity: 0;

    transition: opacity 0.4s, left 0.3s;
  }
}

