@font-face {
  font-family: OpenSans;
  src: url('../fonts/OpenSans-Regular_0.ttf');
  font-weight:normal;
}

@font-face {
  font-family: OpenSans;
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: OpenSans;
  src: url('../fonts/OpenSans-Semibold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: OpenSans;
  src: url('../fonts/OpenSans-Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-family: QuestaGrande;
  src: url('../fonts/Questa-Grande-Regular.otf');
}