.content-banner{
  height:100%;
  display: flex;

  background-color:black;
  @media screen and (max-width: $medium){
    flex-wrap:wrap;
  }
  
  &__left-column{
    width: $right-column-width + $center-column-width;

    @media screen and (max-width: $medium){
      width:100%;
    }
  }

  &__right-column{
    width: $left-column-width;
    background-color:white;

    padding: 45px 130px rem-calc(130px) 64px;

    @media screen and (max-width: $large){
      padding-left: 20px;
      padding-right:20px;
    }

    @media screen and (max-width: $medium){
      width:100%;
    }

    @media screen and (max-height: 700px){
      padding-bottom:20px;
      padding-top: 20px;
    }

    p, dd, dt{
      line-height: 1.5;
      font-weight:400;
    }

    dd, dt{
      @extend p;
      display:inline-block;
    }
    dt{
      width:19%;
    }

    dd{
      width:80%;
    }

    

    h5{
      @extend h4;
      font-size: 18px;
      font-family: $secondary-font;
    }
  }

  &__content{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
  }

  &__logo{
    margin-bottom: 86px;

    @media screen and (max-height: 700px){
      margin-bottom: 20px;
    }

    @media screen and (max-height: 440px){
      margin-bottom: 10px;
    }
  }

  &__title{
    margin-bottom: 40px;
    
    @media screen and (max-height: 700px){
      margin-bottom: 20px;
    }

    @media screen and (max-height: 440px){
      margin-bottom: 10px;
    }
  }

  &__slide{
    height: 100vh;

    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
  }

  &__wrapper{
    padding-bottom: 10px;
  }

}