.banner{
  height:100%;
  width:100vw;
  display:flex;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://placehold.it/350x150');
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;

  position:relative;

  //overlay
  &:before{
    content: "";
    width:100%;
    height:100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
  }

  @media screen and (max-width: $medium), screen and (max-height: 650px){
    height: 100vh;
  }


  &__left-column{
    width:$left-column-width;
    
    display:flex;
    align-items: flex-end;
    justify-content: flex-start;

    position:relative;

    @media screen and (max-width: 959px){
      width:60%;
    }

    @media screen and (max-width: 749px){
      width:100%;
    }
 
  }

  &__right-column{
    width:$center-column-width + $right-column-width;

    @media screen and (max-width: 959px){
      width:0%;
    }
  }

  &__content{
    height: 400px;
    width:100%;
    padding-left: $column-padding-left;
    padding-right: 40px;
    // padding-top: 50px + 63px;
    border-right: 1px solid white; 
    color:white;

    display:flex;
    align-items: center;

    @media screen and (max-width : $large){
      padding-left: $large-column-padding-left;
    }

    @media screen and (max-width: 1200px){
      // padding-top: 63px;
    }

    @media screen and (max-width: 749px){
      border:none;
    }

    @media screen and (max-height: 660px){
      height: 300px;
      padding-left:$medium-column-padding-left;
      padding-right: $medium-column-padding-left;
    }

    @media screen and (max-height: 400px){
      height: 200px;
      // padding-top: 50px;
    }
  }

  &__button{
    margin-bottom: 50px;
  }

  &__logo{
    position: absolute;
    left: $column-padding-left;
    top: 100px;
    color:white;

    @media screen and (max-height: 660px){
      top: 60px;
      left:$medium-column-padding-left;
    }

    @media screen and (max-height: 400px){
      top: 20px;
      left: $small-column-padding-left;
    }

    @media screen and (max-width : $large){
      left: $large-column-padding-left;
    }

  }
}


.banner.full{
  .banner__left-column{
    border-right: 1px solid $primary-color;


    @media screen and (max-width: $small){
      border: none;
    }
  }

  .banner__content{
    border:none;
  }
}