$footer-padding-top: 90px;
.contact{
  display:flex;

  background-color:$grey;
  color:white;
  height:100%;
  

  @media screen and (max-width: $medium){
    flex-wrap:wrap;
  }

  &.light{
    color:black;
    background-color: white;
  }
  &__left-column{
    width: $left-column-width;
    position:relative;
    
    @media screen and (max-width: $medium){
      width:100%;
      height:500px;
    }

    .exit-button{
      display:none;
    }

    @media screen and (min-width: $medium){
      &.uncollapsed{
        .map{
          position: absolute;
          top:0;
          left:0;
          z-index: 9;
          width: 60vw;
        }

        .map__exit-button{
          opacity: 1;
          left:calc(60vw - 20px);
        }
      }
    }
  }

  &__center-column{
    padding-top:$footer-padding-top;
    width: $right-column-width;
    
    display:flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: $large){
      padding-top:$large-column-padding-top;
      padding-left:$large-column-padding-left;
    }

    @media screen and (max-width: $medium){
      padding-left:$medium-column-padding-left;
      padding-top:$medium-column-padding-top;
      width:$left-column-width;
    }

    @media screen and (max-width: $small){
      width:100%;
      justify-content: flex-start;
      align-items:flex-start;
    }
    
    h2{
      margin-bottom: $footer-padding-top;

      @media screen and (max-width: $large){
        margin-bottom: $large-column-padding-top;        
      }

      @media screen and (max-width: $small){
        font-size:26px;
      }
    }

    p{
      font-family: $secondary-font;
      margin-bottom: 40px;
    }
  }

  &__right-column{
    padding-top:$footer-padding-top;
    width: $center-column-width;
    display:flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: $large){
      padding-top:$large-column-padding-top;
      padding-left:$large-column-padding-left;
      
    }

    @media screen and (max-width: $medium){
      padding-left:$medium-column-padding-left;
      padding-top:$medium-column-padding-top;
      width:$center-column-width + $right-column-width;
    }

    @media screen and (max-width: $small){
      width:100%;
    }

    h2{
      margin-bottom: $footer-padding-top;
      @media screen and (max-width: $large){
        width:100%;
        margin-bottom: $large-column-padding-top;
      }

      @media screen and (max-width: $small){
        font-size:26px;
      }
    }
  }

  &__content-wrapper{
    a{
      white-space: nowrap;
      line-height: 1.2;
      transition:color 0.3s;
      &:hover{
        color: $primary-color;
      }
      
    }
  }
}