.fp-section{
  .fp-slide-up{
    opacity: 0;
    transform: translateY(100px);
    transition: transform 0.5s ease 0.3s, opacity 0.5s ease 0.2s;

    &--delay{
      transition: transform 0.5s ease 0.5s, opacity 0.5s ease 0.5s;
    }
  }

  &.active{
    .fp-slide-up{
      opacity:1;
      transform:translateY(0px);
    }
  }
}