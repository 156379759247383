.flexible-sidebar{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height:100%;
  background-color: darken($primary-color, 20%);
  background-size:cover;
  color:white;

  padding:0 25px;
  position: relative;
  z-index: 4;

  @media screen and (max-width: 900px){
    padding: 0 10px;
  }

  @media screen and (max-width: $small){
    flex-direction: row;
    padding-top: $small-column-padding-top;
    padding-bottom: $small-column-padding-top;
  }

  &.open{
    z-index: 0;
  }
   //overlay
   &:before{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: transparentize($primary-color, $amount: 0.4);
  }

  &__button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width:63px;
    height:63px;

    margin-bottom: 37px;

    transition: transform 0.3s;

    @media screen and (max-width: $large){
      margin-bottom: 25px;
    }
  }

  &__title{
    position: relative;
    font-size: rem-calc(50px);
    line-height:1;
    margin-bottom: 30px;

    @media screen and (max-width: $large){
      font-size: rem-calc(36px);
    }

  }

  &__text{
    position: relative;
    p{
      font-family: $primary-font;
      font-size: rem-calc(30px);
      line-height: 45px;

      @media screen and (max-width: $large){
        font-size: rem-calc(22px);
        line-height:22px;
      }
    }
  }
  &__box{
    &:hover{
      .flexible-sidebar__button{
        transform:scale(1.1);
      }
    }
  }
}


.flexible{
  opacity:0;
  width:$right-column-width;
  height:100%;

  position:absolute;
  top:0;
  right:0;
  left:auto;
  bottom:auto;

  background-color:$primary-color;

  transition:  opacity 0.5s,  width 0.5s;


  //overlay
  &:before{
    content: "";
    width:100%;
    height:100%;
    position: absolute;
    background-color: transparentize($color: #bc9072, $amount: 0.17);
  }

  
  &.open{
    opacity:1;
    width:100%;
    transition:  opacity 0.5s,  width 0.5s ease 0.2s;
    .flexible__close-button{
      opacity:1;
    }
  }

  &__close-button{
    opacity:0;
    position:absolute;
    z-index: 1;
    top:20px;
    right:20px;

    width:65px;
    height:65px;

    transition: opacity 0.3s ease 0.7s, transform 0.3s;

    &:hover{
      transform:scale(1.1)
    }


    @media screen and (max-width: $medium){
      top:20px;
      right:20px;
    }
  }

  &__content{
    width:100vw;
    
    padding-top: $column-padding-top;
    padding-bottom: $column-padding-top;
    padding-left: $column-padding-left;
    padding-right: $column-padding-left;

    display:flex;
    color:black;
    position: relative;

    &.white{
      color:white;

      li{
        &:before{
          background-image: url('../img/okey2.svg')
        }
      }
    }

    @media screen and (max-width: $medium){
      padding-top: $medium-column-padding-top;
      padding-bottom: $medium-column-padding-top;
      padding-left: $medium-column-padding-left;
      padding-right: $medium-column-padding-left;
    }

    @media screen and (max-width: $small){
      flex-wrap: wrap;
    }

    .swiper-button-next, .swiper-button-prev{
      //height of slide image / 2 
      top: 736px*0.5;
    }

    h2{
      margin-bottom: 60px;
      font-size: rem-calc(60px);

      @media screen and (max-width: $medium){
        margin-bottom: 30px;
      }
    }

    p, li{
      font-size: rem-calc(22px);
      font-family: $secondary-font;
      font-weight: 400;
      margin-bottom: 60px;

      line-height:1.85;
      
      @media screen and (max-width: $medium){
        margin-bottom: 20px;
        line-height: 1.2;
      }
    }

    li{
      position: relative;
      margin-bottom: 30px;
      &:before{
        content: "";

        position: absolute;
        top:10px;
        left:-40px;

        display:inline-block;
        width:20px;
        height:21px;
        background-size:cover;
        background-image: url('../img/okey.svg');

      }
    }
    
    &-column{
      width:40%;
      
      @media screen and (max-width: $medium){
        width:50%;
      }

      @media screen and (max-width: $small){
        width:100%;
      }

      &:nth-child(2){
        margin-left:13%;

        @media screen and (max-width: $medium){
          margin-left: 5%;
        }

        @media screen and (max-width: $small){
          margin-left:0;  
        }
      }
    }
  }

  &__slide{
    .image{
      height: 736px;
      

      @media screen and (max-width: 1919px){
        height:60vh;
      }

      @media screen and (max-width: $medium){
        height: 50vw;
      }

      @media screen and (max-width: $small){
        height:300px;
      }

      @media screen and (max-width: 450px){
        height: 90vw;
      }
    }

    &-desc{
      margin-top: 20px;
      font-size: rem-calc(16px);
    }
  }
}

.image{
  width:100%;
  height:100%;

  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
}

