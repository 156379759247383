.contact-form{
  width:71%;

  @media screen and (max-width: $large){
    width:100%;
  }

  &__field-wrapper{
    padding: 20px 0;  
    width: 80%;  

    &:first-child{
      padding-top:0;
    }

    @media screen and (max-width: $small){
      width:95%;
    }
  }

  &__label{
    font-size: rem-calc(16px);
    font-family: $secondary-font;
    padding-bottom: 5px;
    display: inline-block;
    width:100%;
  }

  &__input{
    appearance: none;
    border:none;
    background-color:$grey;
    color:white;
    outline: none;
    padding:5px 0;
    font-size: rem-calc(16px);
    font-family: $secondary-font;
    width:100%;
    border-bottom: 1px solid white;
    
    &::placeholder{
      color:white;
    }

    &:focus{
      &::placeholder{
        color:transparent;
      }
    }
  }

  &__submit{
    display:flex;
    justify-content: space-between;
    align-items:center;
    width:100%;
    appearance: none;
    border:0;


    background-color:$primary-color;
    color:white;
    height: 68px;
    position:relative;

    padding: 0px 40px 0px 60px;
    font-size: rem-calc(36px);

    margin-top: 25px;

    font-family: $primary-font;

    transition: background-color 0.3s;
    cursor:pointer;

    &:hover{
      background-color:darken($primary-color, 10%);
    }

    @media screen and (max-width: $large){
      padding: 10px 20px 10px 30px;
      height:50px;
    }

    @media screen and (max-width: $small){
      font-size:24px;
      padding: 10px 15px;
      height:45px;
    }
  }

  .error{
    font-size: 12px;
    display: inline-block;
    font-family: $secondary-font;
    margin-top: 5px;
  }
}

.contact.light .contact-form__input{
  background-color:white;
  border-color:black;
  color:black;
}

.contact.light .contact-form__submit{
  color:white;
}