.menu{
  font-size:1.8rem;

 
  padding-top:80px;
  font-family: $primary-font;
  
  @media screen and (max-width: 1919px){
    padding-top: 40px;
    font-size:1.5rem;
  }

  @media screen and (max-height:750px){
    font-size: 1.6rem;
    padding-top:0px;
  }

  &__list{

  }

  &__list-item{
    margin-bottom:20px;

    @media screen and (max-height:500px){
      margin-bottom: 10px;
    }

    &.active{
      .menu__item{
        color:$primary-color;
      }
    }
  }

  &__item{
    display: inline-block;
    transition: color 0.3s, opacity 0.3s;
    &:hover{
      opacity: 0.6;
      
    }
  }
}


.middle-menu{
  @extend .menu;
  padding-top:140px;
  
  @media screen and (max-width: 959px){
    padding-top: 40px;  
  }

  @media screen and (max-height:750px){
    padding-top: 0px;
  }

  &__list-item{
    @extend .menu__list-item;
  }
  &__element{
    @extend .menu__item;
  }
}

.bottom-menu{
  position: absolute;
  bottom:0;
  left:0;

  width:100%;
  height:100px;
  display:block;

  @media screen and (max-height:750px){
    height: 60px;
  }

  &__list{
    display:flex;
  }

  &__list-item{
    width:33.33%;
    height:100px;
    border-right:2px solid $primary-color;
    display:flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-height:750px){
      height: 60px;
    }
    
    &:last-child{
      border:none;
    }
  }

  &__element{
    transition: transform  0.3s;

    &:hover{
      transform: scale(1.2);
    }
  }
}